<script>
import { Line } from "vue-chartjs";
import { readGraphData } from '@/store/main/getters';
import { dispatchGetGraphData } from '@/store/main/actions';

export default {
  extends: Line,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      gradient: null,
      gradient2: null,
      graphData: null,
      labels: null,
      dataAPR: null,
      dataAPY: null,
      test: null,
    };
  },
  async mounted() {
    await dispatchGetGraphData(this.$store, { plan_name: "APR_BTC_stable" });
    const graphs = readGraphData(this.$store);
    if (graphs) {
      this.dataAPR = graphs.APRs;
      this.dataAPY = graphs.APYs;
      this.labels = graphs.dates;
    }

    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
    this.gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

    this.gradient2.addColorStop(0, "rgba(54, 162, 235, 0.9)");
    this.gradient2.addColorStop(0.25, "rgba(54, 162, 235, 0.3)");
    this.gradient2.addColorStop(0.3, "rgba(54, 162, 235, 0.1)");
    this.gradient2.addColorStop(1, "rgba(54, 162, 235, 0)");

    this.renderChart(
      {
        // labels: [27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27],
        labels: this.labels,
        datasets: [
          {
            label: "年利率",
            borderColor: "#FC2525",
            // pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "white",
            backgroundColor: this.gradient,
            data: this.dataAPR,
            pointRadius: 0,
            // data: [27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, this.test],
          },
          {
            label: "年報酬率",
            borderColor: "#05CBE1",
            // pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: this.gradient2,
            data: this.dataAPY,
            pointRadius: 0,
          }
        ]
      },
      { 
        tooltips: {
          enabled: true,
          intersect: false,
          mode: "index"
        },
        hover: {
          mode: 'index',
          intersect: false
        },
        responsive: true, 
        maintainAspectRatio: false,
        "legend": {
            "display": true,
            "labels": {
                "fontSize": 16,
            },
            "onClick": null,
        },
        // title: {
        //         display: false,
        //         text: '歷史利率',
        //         fontSize: 20,
        //         fontColor: 'black',
        //     },
        scales: {
            yAxes: [{
              display: true,
                ticks: {
                    fontSize: 14,
                    suggestedMax: 10,
                    suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                    callback: function(value, index, values) {
                        return value + ' %';
                    }
                }
            }],
            xAxes: [{
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 20,
                    fontSize: 14,
                }
            }]
        },
      }
    );
  }
};
</script>
